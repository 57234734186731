import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

import Slider from "react-slick";

const Stava = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 0,
  };
  const lang = "pl";

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    autoplay: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <Layout
      seo={{
        title: "Stava",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/stava-og-image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #ff7a21, #fc7114, #ff6803, #e36009, #c75910 )",
        icons: "#ff6803",
        navClass: "stava",
        ogImage: require("../../assets/img/portfolio/stava_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/stava/",
      }}
    >
      <PortfolioHeader name="stava" height="214" />
      <section className="container-fluid stava-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Stava</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Zaprojektowaliśmy i zakodowaliśmy dla firmy Stava strony
                inwestycyjne, które w wyniku dalszych działań marketingowych{" "}
                <strong>
                  pozwoliły pozyskać ponad 3,45 miliona złotych opłaconych
                  zapisów i 407 akcjonariuszy z emisji akcji w 54 dni.
                </strong>{" "}
                Pełne case study znajdziesz{" "}
                <a
                  href="https://when.pl/case-study/stava"
                  className="link"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  tutaj
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid stava-section-2">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--macbook">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/stava_full.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid stava-section-3">
        <div className="row">
          <div className="col-md-6">
            <img
              src={require("../../assets/img/portfolio/stava_img_1.png")}
              alt=""
              className="img-fluid stava-img-1"
            />
          </div>
          <div className="col-md-6">
            <img
              src={require("../../assets/img/portfolio/stava_img_2.png")}
              alt=""
              className="img-fluid stava-img-2"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid stava-section-4">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <img
              src={require("../../assets/img/portfolio/stava_img_3.png")}
              alt=""
              className="img-fluid stava-img-3"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid stava-section-5">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <Slider {...settings}>
              <div>
                <img
                  src={require("../../assets/img/portfolio/stava_ipad_1.png")}
                  alt="Stava"
                  className="img-fluid"
                />
              </div>
              <div>
                <img
                  src={require("../../assets/img/portfolio/stava_ipad_2.png")}
                  alt="Stava"
                  className="img-fluid"
                />
              </div>
              <div>
                <img
                  src={require("../../assets/img/portfolio/stava_ipad_3.png")}
                  alt="Stava"
                  className="img-fluid"
                />
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Stava;
